// Load .env vars into the config

const formattedVars: { [key: string]: string } = {};
const configEntries: [key: string, value: string][] = Object.entries(process.env);

for (const [key, value] of configEntries) {
  formattedVars[key.replace('VUE_APP_', '').toLowerCase()] = value;
}

type Config = {
  [key: string]: string;
};

const config: Config = {
  ...formattedVars,
  ...{
    // Add app config/constants here that you do not want in the .env files
    application_name: 'Opendock Photon',
    environment: process.env?.NODE_ENV ?? 'development'
  }
};

export default config;
