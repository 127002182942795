import axios, { AxiosResponse } from 'axios';
import { GenericArrayOfObjects, IClient, INormalizedResponse } from '@/interfaces/api';
import config from '@/config/config';
import { isObject } from 'class-validator';

export interface INovaResponse {
  action: string;
  count: number;
  data: GenericArrayOfObjects;
  entity: string;
  page: number;
  pageCount: number;
  total: number;
}

export type ConstructorParams = {
  token: string;
};
export default class NovaClient implements IClient {
  readonly client;
  constructor({ token }: ConstructorParams) {
    this.client = axios.create({
      baseURL: config.neutron_url,
      headers: {
        Authorization: `Bearer ${token}`,
        ...NovaClient.getGlobalHeaders()
      },
      paramsSerializer: params =>
        Object.keys(params)
          .filter(key => params[key] !== undefined)
          .map(key => {
            if (isObject(params[key])) {
              params[key] = JSON.stringify(params[key]);
            }
            if (Array.isArray(params[key])) {
              return params[key]
                .map((v: string | number | boolean) => `${key}[]=${encodeURIComponent(v)}`)
                .join('&');
            }
            return `${key}=${encodeURIComponent(params[key])}`;
          })
          .join('&')
    });
  }

  public static getGlobalHeaders() {
    return {
      'X-UI-AppName': 'Photon',
      'X-UI-Version': process.env.VUE_APP_NOVA_VERSION
    };
  }

  normalizeResponse(response: AxiosResponse): INormalizedResponse {
    const novaResponse: INovaResponse = response.data;
    let data = novaResponse?.data ?? [novaResponse];
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      count: novaResponse.count,
      data,
      errors: [],
      statusCode: response.status
    };
  }

  async get(resource: string, params?: object) {
    const response = await this.client.get(resource, { params: params });
    return this.normalizeResponse(response);
  }

  async post(resource: string, params?: object): Promise<INormalizedResponse> {
    const response = await this.client.post(resource, params);
    return this.normalizeResponse(response);
  }

  async patch(resource: string, params?: object): Promise<INormalizedResponse> {
    const response = await this.client.patch(resource, params);
    return this.normalizeResponse(response);
  }
}
