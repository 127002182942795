import { APIs } from '@/enums/APIs';
import { IApiService } from '@/interfaces/api';
import { AppointmentPlusApiService } from '@/services/appointment_plus/AppointmentPlusApiService';
import { NovaApiService } from '@/services/nova/NovaApiService';

type getClientParamsCredentials = {
  api: APIs;
  credentials: {
    userName: string;
    password: string;
  };
  url?: string;
  token?: never;
};

type getClientParamsToken = {
  api: APIs;
  credentials?: never;
  url?: string;
  token: string;
};

export function getClient({
  api,
  credentials,
  url,
  token
}: getClientParamsCredentials | getClientParamsToken): IApiService | NovaApiService {
  let service: IApiService | NovaApiService;
  switch (api) {
    case APIs.AP:
      if (credentials) {
        service = getAPApiService(credentials, url as string);
      } else {
        throw TypeError('Credentials are required.');
      }
      break;
    case APIs.NOVA:
      service = new NovaApiService({ token: token as string });
      break;
    default:
      if (credentials) {
        service = getAPApiService(credentials, url as string);
      } else {
        throw TypeError('Credentials are required.');
      }
      break;
  }

  return service;
}

function getAPApiService(credentials: { userName: string; password: string }, url: string) {
  return new AppointmentPlusApiService({
    userName: credentials.userName as string,
    password: credentials.password as string,
    url
  });
}
