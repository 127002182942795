import { utils, WorkBook, WorkSheet, writeFileXLSX } from 'xlsx-js-style';
import {
  INovaAppointment,
  INovaCarrierData,
  INovaDock,
  INovaLoadType,
  INovaUser,
  INovaWarehouse
} from '@/interfaces/nova';
import { getProperty, ucFirst } from '@/services/util';

export interface IExportedData {
  warehouses: INovaWarehouse[];
  loadTypes: INovaLoadType[];
  docks: INovaDock[];
  appointments: INovaAppointment[];
  users?: INovaUser[];
  carriers?: INovaCarrierData[];
}

const MIN_COLUMN_WIDTH = 10;
const MAX_COLUMN_WIDTH = 100;

export function exportWorkbook(
  exportedData: IExportedData | Partial<IExportedData>,
  account: string,
  filenamePrefix = '-export'
) {
  const workBook: WorkBook = utils.book_new();
  buildWorkbook(workBook, exportedData);
  writeFileXLSX(workBook, `${account}${filenamePrefix}.xlsx`);
}

function buildWorkbook(workbook: WorkBook, data: IExportedData | Partial<IExportedData>) {
  Object.keys(data).forEach(key => {
    createSheet(workbook, getProperty(data, key), ucFirst(key));
  });
}

export function createSheet(workbook: WorkBook, data: { [key: string]: any }[], sheetName: string) {
  const sheet: WorkSheet = utils.json_to_sheet(data);
  if (data.length) {
    const columnOpts: { wch: number }[] = [];
    Object.keys(data[0]).forEach((key: string) => {
      let autoColumnWidth = data.reduce((w, r) => Math.max(w, getProperty(r, key)?.length), 10);
      if (Number.isNaN(autoColumnWidth)) {
        autoColumnWidth = MIN_COLUMN_WIDTH;
      }
      columnOpts.push({
        wch:
          autoColumnWidth < MIN_COLUMN_WIDTH
            ? MIN_COLUMN_WIDTH
            : autoColumnWidth > MAX_COLUMN_WIDTH
            ? MAX_COLUMN_WIDTH
            : autoColumnWidth
      });
    });
    sheet['!cols'] = columnOpts;
  }

  utils.book_append_sheet(workbook, sheet, sheetName);
}
