import {
  INovaSchedule,
  INovaWarehouse,
  ClockSpan,
  INovaLoadType,
  INovaDock,
  INovaAppointment
} from '@/interfaces/nova';
import { isPhoneNumber } from 'class-validator';
import {
  concatOptionalField,
  getProperty,
  minutesSinceMidnightToClock,
  deleteProperty,
  cleanObjectArr,
  formatPhone,
  emailToFormattedArray,
  cleanWhiteSpace
} from '@/services/util';
import {
  IAPAppointment,
  IAPDock,
  IAPLoadType,
  IAPMaptsScheduleItem,
  IAPSaptsSchedule,
  IAPWarehouse,
  IGetAppointmentsOptions
} from '@/interfaces/ap';
import { dayMap } from '@/enums/maps';
import { GenericArrayOfObjects } from '@/interfaces/api';
import { DateTime } from 'luxon';
import { AppointmentPlusDateFormats } from '@/enums/appointmentplus/appointmentplusEnums';
import { CustomFieldType, NovaDateFormats } from '@/enums/nova/novaEnums';

export function mapWarehouse(wsData: IAPWarehouse): INovaWarehouse {
  return {
    externalId: wsData.location_id,
    externalHeadquartersId: wsData.headquarters_id,
    name: wsData.location_name,
    street: concatOptionalField(wsData.address1, wsData.address2),
    city: wsData.city,
    state: wsData.state,
    zip: wsData.zip,
    timezone: wsData.timezone,
    contactName: concatOptionalField(wsData.contact_first_name, wsData.contact_last_name),
    phone: isPhoneNumber(wsData.phone) ? wsData.phone : '',
    email: wsData.email,
    notes: '',
    country: wsData.country,
    instructions: wsData.directions,
    facilityNumber: wsData.store_number ?? null,
    schedule: JSON.stringify(wsData.schedule),
    customApptFieldsTemplate: wsData.custom_fields ? JSON.stringify(wsData.custom_fields) : null,
    allowCarrierScheduling: wsData.hide_from_customer === 'true' ? false : true
  };
}

export function mapWarehouseSchedule(schedule: IAPSaptsSchedule): INovaSchedule {
  deleteProperty(schedule, 'location_id');
  schedule = cleanObjectArr(
    schedule as unknown as GenericArrayOfObjects
  ) as unknown as IAPSaptsSchedule;
  const mappedSchedule: INovaSchedule = {};
  Object.keys(schedule).forEach(key => {
    const keyParts = key.split('_');
    const day: string = getProperty(dayMap, keyParts[1]);
    const clockSpanKey = keyParts[0] === 'open' ? 'start' : 'end';
    if (!Object.prototype.hasOwnProperty.call(mappedSchedule, day)) {
      const clockSpan: ClockSpan = {
        start: '',
        end: ''
      };
      mappedSchedule[day] = [];
      getProperty(mappedSchedule, day).push(clockSpan);
    }

    const parsedTime = parseInt(schedule[key]);
    const time = parsedTime === 1440 ? 1439 : parsedTime;
    getProperty(mappedSchedule, day)[0][clockSpanKey] = minutesSinceMidnightToClock(time);
  });

  mappedSchedule.version = 1;
  return mappedSchedule;
}

export function mapLoadType(wsData: IAPLoadType): INovaLoadType {
  const direction = wsData.title.toLowerCase().includes('outbound') ? 'Outbound' : 'Inbound';
  return {
    externalId: wsData.service_id,
    externalWarehouseId: wsData.c_id,
    name: wsData.title,
    direction,
    duration_min: parseInt(wsData.default_service_time) || 1,
    allowCarrierScheduling: wsData.display === 'yes' ? true : false,
    addOn:
      wsData.addon.toLowerCase() === 'yes' || wsData.addon.toLowerCase() === 'true' ? true : false
  };
}

export function mapDock(wsData: IAPDock): INovaDock {
  return {
    externalId: wsData.employee_id,
    externalWarehouseId: wsData.c_id,
    externalLoadTypeIds: JSON.stringify(wsData.load_type_ids),
    name: wsData.screen_name,
    isActive: wsData.status.toLowerCase() === 'active',
    allowCarrierScheduling: wsData.display === 'yes' ? true : false,
    schedule: JSON.stringify(wsData.schedule),
    isMapts: wsData.isMapts ?? false
  };
}

export function mapDockSchedule(wsData: IAPDock, schedule: IAPSaptsSchedule) {
  const mappedSchedule: INovaSchedule = {};
  Object.keys(schedule).forEach(key => {
    const keyParts = key.split('_');
    const lastEl = keyParts.pop();
    const keyCheck = keyParts.join('_');
    const keysToProcess = ['first_appt_time', 'last_appt_time'];
    if (keysToProcess.find(el => el === keyCheck) && getProperty(wsData, key)) {
      if (lastEl) {
        const day = lastEl.toLowerCase();
        const clockSpanKey = keyParts[0] === 'first' ? 'start' : 'end';
        if (!Object.prototype.hasOwnProperty.call(mappedSchedule, day)) {
          const clockSpan: ClockSpan = {
            start: '',
            end: ''
          };
          mappedSchedule[day] = [];
          getProperty(mappedSchedule, day).push(clockSpan);
        }

        const minutesFromMidnight = getProperty(wsData, key);
        if (minutesFromMidnight) {
          const parsedTime = parseInt(minutesFromMidnight);
          const time = parsedTime === 1440 ? 1439 : parsedTime;
          getProperty(mappedSchedule, day)[0][clockSpanKey] = minutesSinceMidnightToClock(time);
        }
      }
    }
  });

  mappedSchedule.version = 1;
  return mappedSchedule;
}

export function mapMaptsDockSchedule(
  dock: IAPDock,
  schedule: IAPMaptsScheduleItem[]
): INovaSchedule {
  const mappedSchedule: INovaSchedule = {};
  schedule.forEach(scheduleItem => {
    const day = scheduleItem.day.toLowerCase();
    if (!Object.prototype.hasOwnProperty.call(mappedSchedule, day)) {
      mappedSchedule[day] = [];
    }
    getProperty(mappedSchedule, day).push({
      start: minutesSinceMidnightToClock(parseInt(scheduleItem.start_time)),
      end: minutesSinceMidnightToClock(parseInt(scheduleItem.end_time))
    });
  });
  mappedSchedule.version = 1;
  return mappedSchedule;
}

export function mapAppointment(
  wsData: IAPAppointment,
  options?: IGetAppointmentsOptions
): INovaAppointment {
  const { start, end } = getStartEndTimes();
  const emails = Array.from(new Set(emailToFormattedArray(wsData.email)));
  const ccEmails = emails.slice(1);

  const isCustomStatus =
    wsData.appt_status_description &&
    !['requested', 'scheduled', 'completed', 'cancelled', 'noshow', 'inprogress'].includes(
      wsData.appt_status_description.toLowerCase()
    );
  const tags =
    isCustomStatus && options?.addCustomStatusAsTag ? [wsData.appt_status_description] : [];
  tags.push('Imported');

  return {
    externalId: wsData.appt_id,
    externalWarehouseId: wsData.c_id,
    externalLoadTypeId: wsData.service_id,
    externalDockId: wsData.employee_id,
    externalCustomerId: wsData.customer_id,
    externalCompanyName: wsData.company_name,
    status: wsData.appt_status_description,
    start: start,
    end: end,
    refNumber: wsData.po_number,
    firstName: wsData.first_name,
    lastName: wsData.last_name,
    email: emails?.[0],
    ccEmails: cleanWhiteSpace(ccEmails.join(', ')),
    phone: formatPhone(wsData.phone ?? ''),
    tags: tags.join(','),
    isRecurring: parseInt(wsData.recur_id) > 0,
    externalCreateTimestamp: wsData.createTimestamp,
    customFields: JSON.stringify(wsData.customFields),
    notes: wsData.customer_notes,
    account: wsData.account,
    middleName: wsData.middle_name
  };

  function getStartEndTimes() {
    const date = DateTime.fromFormat(wsData.date, AppointmentPlusDateFormats.API);
    const isSplitDay = wsData.start_time > wsData.end_time;
    const start = `${date.toFormat(NovaDateFormats.ISODATE)}T${minutesSinceMidnightToClock(
      wsData.start_time,
      true
    )}`;
    const end = `${date
      .plus({ day: isSplitDay ? 1 : 0 })
      .toFormat(NovaDateFormats.ISODATE)}T${minutesSinceMidnightToClock(wsData.end_time, true)}`;
    return { start, end };
  }
}

export function getNovaCustomFieldType(fieldType: string): CustomFieldType {
  const singleDropDowns = ['radio', 'dropdown', 'dd'];
  const multiDropDowns = ['c', 'checkbox', 'radio'];

  let type = CustomFieldType.String;

  if (singleDropDowns.includes(fieldType)) {
    type = CustomFieldType.DropDown;
  } else if (multiDropDowns.includes(fieldType)) {
    type = CustomFieldType.DropDownMultiSelect;
  }

  return type;
}

export const validCustomFieldKeys = Object.freeze([
  'label',
  'type',
  'name',
  'description',
  'order',
  'value',
  'required',
  'requiredForWarehouse',
  'requiredForCarrier',
  'dropDownValues',
  'hiddenFromCarrier',
  'placeholder',
  'minLengthOrValue',
  'maxLengthOrValue'
]);

export function removeInvalidCustomFieldsKeys(
  customFields: GenericArrayOfObjects
): GenericArrayOfObjects {
  customFields = customFields.map(field => {
    Object.entries(field).forEach(([key]) => {
      if (!validCustomFieldKeys.includes(key)) {
        delete field[key];
      }
    });
    return field;
  });

  return customFields;
}
