import { GenericArrayOfObjects, INormalizedResponse } from '@/interfaces/api';
import { notify } from '@kyvg/vue3-notification';

export const EMAIL_SEPARATION_REGEX = /[,;]/g;

const notificationConfigs = {
  success: { icon: 'mdi-check-circle-outline', appClasses: 'success' },
  error: { icon: 'mdi-alert-circle-outline', appClasses: 'error' },
  warning: { icon: 'mdi-alert-circle-outline', appClasses: 'warning' },
  info: { icon: 'mdi-information-outline', appClasses: 'info' }
};

export function concatOptionalField(field1: string, field2?: string): string {
  return field1 + (field2 ? ` ${field2}` : '');
}

export function getProperty(obj: any, propertyName: string) {
  return obj[propertyName];
}

export function deleteProperty(o: any, propertyName: string): void {
  delete o[propertyName];
}

export function minutesSinceMidnightToClock(minutes: number, leadingZeroHour = false): string {
  let hours: string | number = Math.floor(minutes / 60);
  if (leadingZeroHour) {
    hours = leftPadString(hours);
  }
  let remainder = leftPadString(minutes % 60);
  // Instead of 24:00, we need to format to 23:59 so it fits the expected 'end of day' time in Nova
  if (hours === 24) {
    hours = 23;
    remainder = '59';
  }
  return `${hours}:${remainder}`;
}

export function leftPadString(str: string | number, charactersToPad = 2) {
  return str.toString().padStart(charactersToPad, '0');
}

export function cleanObjectArr(objectArr: GenericArrayOfObjects) {
  Object.keys(objectArr).forEach(key => {
    if (!getProperty(objectArr, key)) {
      deleteProperty(objectArr, key);
    }
  });
  return objectArr;
}

function getNotificationConfig(type: string) {
  return getProperty(notificationConfigs, type);
}

type SystemNotifyParams = {
  message: string;
  type?: string;
  title?: string;
  options?: { [key: string]: any };
};
export function systemNotify({ message, type = 'success', options = {} }: SystemNotifyParams) {
  options = {
    ...{
      duration: 5000,
      clean: false,
      actionText: null,
      actionFn: null
    },
    ...options
  };
  notify({
    title: ucFirst(type),
    text: message,
    type: type,
    duration: options.duration,
    data: { ...getNotificationConfig(type), ...options },
    clean: options.clean
  });
}

export function ucFirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function hasErrors(response: INormalizedResponse): boolean {
  return Boolean(response.errors?.length);
}

export function flashErrors(errors: string[]) {
  errors.forEach(error => {
    systemNotify({ message: error, type: 'error' });
  });
}

export function flashErrorsIfExist(response: INormalizedResponse): boolean {
  if (hasErrors(response)) {
    flashErrors(response.errors as string[]);
  }

  return hasErrors(response);
}

export function successRes(status: number) {
  return status >= 200 && status < 300;
}

export function formatPhone(phone: string): string {
  let formattedPhone = String(phone).replace(/[^a-zA-Z0-9]/g, '');
  if (formattedPhone.charAt(0) === '1') {
    formattedPhone = formattedPhone.substring(1);
  }
  return formattedPhone;
}

export function keyCompaniesByName(companyArr: GenericArrayOfObjects) {
  const companiesKeyedByName: { [key: string]: any } = {};
  companyArr.forEach(company => {
    companiesKeyedByName[company.name] = company;
  });
  return companiesKeyedByName;
}

export function cleanWhiteSpace(str = '', replacement = ' '): string {
  if (!str) {
    return '';
  }
  return str.replace(/\s\s+/g, replacement).trim();
}

export function makeUniqueObjectArray(arr: { [key: string]: any }[]): { [key: string]: any }[] {
  return arr.filter((value, index) => {
    const objectVal = JSON.stringify(value);
    return (
      index ===
      arr.findIndex(obj => {
        return JSON.stringify(obj) === objectVal;
      })
    );
  });
}

export async function sleep(milliseconds = 0) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function emailStrToFormattedArray(emailStr: string): string[] {
  emailStr = cleanWhiteSpace(emailStr, '');
  return emailStr ? emailStr.split(EMAIL_SEPARATION_REGEX).map(email => email.toLowerCase()) : [];
}

export function emailToFormattedArray(email: string | string[]): string[] {
  if (Array.isArray(email)) {
    return emailStrToFormattedArray(email.join(','));
  } else {
    return email ? emailStrToFormattedArray(email) : [];
  }
}

export function objHasProperty(obj: { [key: string]: any }, property: string) {
  return Object.prototype.hasOwnProperty.call(obj, property);
}
