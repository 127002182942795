import { useRoute, useRouter } from 'vue-router';

export function useRouting() {
  const route = useRoute();
  const router = useRouter();

  const routeLoggedIn = async () => {
    if (route.name === 'login') {
      await router.replace({ name: 'home' });
    }
  };
  const routeLoggedOut = async () => {
    if (route.name !== 'login') {
      await router.replace({ name: 'login' });
    }
  };

  return { routeLoggedIn, routeLoggedOut };
}
