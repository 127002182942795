import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DataTransferView from '@/views/DataTransferView.vue';
import LoginPage from '@/components/auth/LoginPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginPage,
    meta: {
      title: 'Login | Opendock Photon',
      shouldHideBanner: true,
      isFullScreen: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: DataTransferView,
    meta: {
      title: 'Home'
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
