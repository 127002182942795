import { INovaLoadType, INovaDock, INovaAppointment, INovaWarehouse } from '@/interfaces/nova';

export function mapWarehouse(warehouse: any): INovaWarehouse {
  return {
    ...warehouse,
    externalId: warehouse.id,
    schedule: JSON.stringify(warehouse.schedule),
    customApptFieldsTemplate: warehouse.customApptFieldsTemplate
      ? JSON.stringify(warehouse.customApptFieldsTemplate)
      : null,
    ppeRequirements: JSON.stringify(warehouse.ppeRequirements),
    amenities: JSON.stringify(warehouse.amenities)
  };
}

export function mapLoadType(loadType: any): INovaLoadType {
  return {
    ...loadType,
    externalId: loadType.id,
    externalWarehouseId: loadType.warehouseId
  };
}

export function mapDock(dock: any): INovaDock {
  return {
    ...dock,
    externalId: dock.id,
    externalWarehouseId: dock.warehouseId,
    externalLoadTypeIds: JSON.stringify(dock.loadTypeIds),
    schedule: dock.schedule ? JSON.stringify(dock.schedule) : null
  };
}

export function mapAppointment(appointment: any): INovaAppointment {
  const tags = appointment.tags?.length
    ? Array.from(new Set([...appointment.tags, 'Imported'])).join(',')
    : 'Imported';
  return {
    ...appointment,
    externalId: appointment.id,
    externalWarehouseId: appointment.dock.warehouseId,
    externalLoadTypeId: appointment.loadTypeId,
    externalDockId: appointment.dockId,
    externalCustomerId: appointment.userId,
    externalCompanyName: appointment.user.company?.name,
    isNovaExport: true,
    tags,
    email: appointment.user?.email ?? appointment.ccEmails?.[0],
    customFields: appointment.customFields ? JSON.stringify(appointment.customFields) : null,
    firstName: appointment.user?.firstName,
    lastName: appointment.user?.lastName
  };
}
