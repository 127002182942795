export enum NovaDateFormats {
  ISODATE = 'yyyy-MM-dd'
}

export enum NovaAppointmentStatus {
  SCHEDULED = 'Scheduled'
}

export enum NovaUserRoles {
  GOD = 'role_god',
  INTERNAL = 'role_internal',
  OWNER = 'role_owner',
  ADMIN = 'role_admin',
  OPERATOR = 'role_operator',
  ATTENDANT = 'role_attendant',
  SPECTATOR = 'role_spectator',
  BASE = 'role_base'
}

export enum CustomFieldType {
  String = 'str',
  Date = 'date',
  Bool = 'bool',
  Document = 'doc',
  MultiDocument = 'multidoc',
  Number = 'int',
  Email = 'email',
  Phone = 'phone',
  DropDown = 'dropdown',
  DropDownMultiSelect = 'dropdownmultiselect'
}
