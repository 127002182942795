
import { computed, reactive } from 'vue';
import { APIs } from '@/enums/APIs';
import AppointmentPlusForm from '@/components/forms/AppointmentPlusForm.vue';
import NovaForm from '@/components/forms/NovaForm.vue';
import NovaImportForm from '@/components/forms/NovaImportForm.vue';

export default {
  name: 'DataTransferView',
  components: {
    NovaImportForm,
    AppointmentPlusForm,
    NovaForm
  },
  setup() {
    const state = reactive({
      selectedApi: APIs.AP
    });
    const apiOptions = computed(() => Object.values(APIs));

    return {
      apiOptions,
      state,
      APIs
    };
  }
};
