import { AxiosResponse } from 'axios';
import axios from 'axios';
import { IClient, INormalizedResponse } from '@/interfaces/api';

export const appointmentPlusUrls = [
  {
    title: 'Dedicated Opendock URL',
    value: 'https://opendock-ws.appointment-plus.com'
  },
  {
    title: 'General Use URL (Requires CORS Extension)',
    value: 'https://ws.appointment-plus.com'
  }
];

export type WSResponse = {
  resource: string;
  action: string;
  request: string;
  errors?: string[];
  result: string;
  count: string;
  data: object | string;
};

const defaultResponseVals: INormalizedResponse = {
  count: 0,
  data: [],
  errors: [],
  statusCode: 500
};

export default class WSClient implements IClient {
  readonly client;
  constructor(userName: string, password: string, url: string) {
    this.client = axios.create({
      baseURL: url,
      headers: {
        authorization: 'Basic ' + btoa(userName + ':' + password)
      },
      params: {
        response_type: 'JSON'
      }
    });
  }

  normalizeResponse(response: AxiosResponse): INormalizedResponse {
    if (response) {
      if (response?.data === '') {
        return {
          ...defaultResponseVals,
          errors: ['Site ID or WS API Key is incorrect or invalid']
        };
      } else {
        const wsResponse: WSResponse = response.data;
        if (wsResponse.result === 'fail') {
          return {
            ...defaultResponseVals,
            errors: wsResponse.errors?.length ? wsResponse.errors : ['Unknown WS Error']
          };
        } else {
          if (!wsResponse?.data) {
            return { ...defaultResponseVals, statusCode: 200 };
          }
          return {
            count: parseInt(wsResponse.count),
            data: this.convertDataToArray(wsResponse.data),
            statusCode: 200
          };
        }
      }
    } else {
      return {
        ...defaultResponseVals,
        errors: ['Error Contacting Web Services']
      };
    }
  }

  async post(resource: string, params?: object): Promise<INormalizedResponse> {
    const response = await this.client.post(resource, null, { params: params });
    return this.normalizeResponse(response);
  }

  convertDataToArray(data: string | object | object[]): object[] {
    if (typeof data === 'string') {
      return [];
    } else if (typeof data === 'object' && !Array.isArray(data)) {
      return [data];
    } else {
      return data;
    }
  }
}
