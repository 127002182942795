<template>
  <div>
    <v-form ref="form" @keyup.enter="submit">
      <v-container>
        <v-text-field
          :required="true"
          type="email"
          variant="outlined"
          density="compact"
          v-model="state.email"
          v-model:error-messages="fieldErrors.email"
          label="Email"
          hide-details="auto"
          :persistent-placeholder="true"
          @blur="validateEmail"
          placeholder="Type your email ..."></v-text-field>
        <v-text-field
          :required="true"
          type="password"
          variant="outlined"
          density="compact"
          v-model="state.password"
          v-model:error-messages="fieldErrors.password"
          label="Password"
          hide-details="auto"
          :persistent-placeholder="true"
          placeholder="Type your password ..."></v-text-field>
        <primary-button block x-large class="login-button" @click="submit" :loading="state.loading">
          Log in
        </primary-button>
      </v-container>
    </v-form>
  </div>
</template>

<script setup>
import PrimaryButton from '@/components/buttons/PrimaryButton';
import { reactive, watch } from 'vue';
import { isEmail } from 'class-validator';
import { useStore } from 'vuex';
import { successRes, systemNotify } from '@/services/util';
import { useRouting } from '@/composables/router';
import { NovaUserRoles } from '@/enums/nova/novaEnums';

const store = useStore();
const { routeLoggedIn } = useRouting();

const state = reactive({
  email: '',
  password: '',
  loading: false
});

/**
 * Submit the form
 * @public
 * @returns {Promise<void>}
 */
const submit = async () => {
  if (!isValid()) {
    return;
  }

  state.loading = true;
  store
    .dispatch('login', {
      email: state.email,
      password: state.password
    })
    .then(async response => {
      if (!successRes(response.status)) {
        systemNotify({
          message: response.data.message ?? response.data.error,
          type: 'error'
        });
      }
    })
    .finally(() => (state.loading = false));
};

// VALIDATION
const fieldErrors = reactive({
  email: '',
  password: ''
});
const errorMessages = {
  email: {
    required: 'An Email is required',
    invalid: 'Email is invalid'
  },
  password: 'Password is required'
};
const isValid = () => {
  if (!state.email) {
    fieldErrors.email = errorMessages.email.required;
  }
  if (state.email && !isEmail(state.email)) {
    fieldErrors.email = errorMessages.email.invalid;
  }
  if (!state.password) {
    fieldErrors.password = errorMessages.password;
  }
  if (fieldErrors.email || fieldErrors.password) {
    return false;
  }

  return true;
};

const validateEmail = () => {
  if (!isEmail(state.email)) {
    fieldErrors.email = errorMessages.email.invalid;
  }
};

watch(
  () => state.email,
  newEmail => {
    if (newEmail) {
      fieldErrors.email = '';
    } else {
      fieldErrors.email = errorMessages.email.required;
    }
  }
);

watch(
  () => state.password,
  newPass => {
    if (newPass) {
      fieldErrors.password = '';
    } else {
      fieldErrors.password = errorMessages.password;
    }
  }
);

watch(
  () => store.state.me,
  async newMe => {
    if (newMe?.role === NovaUserRoles.INTERNAL) {
      await routeLoggedIn();
    } else {
      systemNotify({
        message: 'Access Forbidden',
        type: 'error'
      });
    }
  }
);
</script>

<style lang="scss" scoped>
form {
  max-width: 100%;
  width: 500px;
  margin: auto;
}
</style>
