import { sleep } from '@/services/util';
import { ref } from 'vue';

export function useProgress() {
  const currentPerc = ref(0);
  const currentStepMessage = ref('');
  const progressInterval = ref(0);
  const shouldShowProgressDialog = ref(false);

  const createInterval = (message = '', max = 0, timeout = 750) => {
    clear();
    progressInterval.value = setInterval(() => {
      if (currentPerc.value < max) {
        // Cannot just increment the ref value - it doesn't actually update as you'd expect
        update(message, (currentPerc.value = currentPerc.value + 1));
      }
    }, timeout);
  };

  const zoom = async (targetProgress = 0, timeout = 150) => {
    while (currentPerc.value < targetProgress) {
      currentPerc.value++;
      await sleep(timeout);
    }
  };

  const update = (message = '', percentage = 0) => {
    currentStepMessage.value = message;
    currentPerc.value = percentage;
  };

  const clear = () => {
    clearInterval(progressInterval.value);
  };

  const setDialogVisibility = (shouldShow: boolean) => {
    shouldShowProgressDialog.value = shouldShow;
  };

  return {
    createInterval,
    zoom,
    update,
    clear,
    setDialogVisibility,
    shouldShowProgressDialog,
    currentStepMessage,
    currentPerc
  };
}
