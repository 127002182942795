import { createStore } from 'vuex';
import axios, { AxiosInstance } from 'axios';
import { useContext } from '@loadsmart/vue-feature-flags';
import { localStorageKeys } from '@/enums/local_storage';
import config from '@/config/config';
import NovaClient from '@/clients/nova/NovaClient';
import { INovaUser } from '@/interfaces/nova';

export interface StoreState {
  me: null | INovaUser;
  axios: AxiosInstance;
}

export default createStore<StoreState>({
  state: {
    me: null,
    axios: axios.create({
      baseURL: config.neutron_url,
      headers: NovaClient.getGlobalHeaders()
    })
  },
  getters: {
    accessToken() {
      return localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
    }
  },
  mutations: {
    setMe(state, me: INovaUser | null) {
      const { updateContext } = useContext();
      state.me = me;

      return updateContext({
        userId: me?.id,
        orgId: me?.orgId,
        role: me?.role,
        companyId: me?.companyId
      });
    },
    setAccessToken(state, token) {
      if (!token) {
        localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
        return;
      }
      state.axios.defaults.headers.Authorization = `Bearer ${token}`;
    },
    removeAccessToken(state) {
      localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
      delete state.axios.defaults.headers.Authorization;
    }
  },
  actions: {
    async login({ dispatch, commit, state }, { email, password }) {
      const response = await state.axios
        .post('auth/login', {
          email: email,
          password: password
        })
        .catch(e => {
          return e.response;
        });

      if (response?.status >= 200 || response?.status < 300) {
        commit('setAccessToken', response.data.access_token);
        localStorage.setItem(localStorageKeys.ACCESS_TOKEN, response.data.access_token);
        dispatch('getMe');
      }

      return response;
    },
    async loginAs({ dispatch, commit }, accessToken) {
      commit('setAccessToken', accessToken);
      dispatch('getMe');
    },
    async getMe({ commit, state, getters, dispatch }) {
      if (!getters.accessToken) {
        dispatch('logout');
        return;
      }

      const response = await state.axios.get('auth/me');

      if (response?.data) {
        commit('setMe', response.data);
      } else {
        dispatch('logout');
      }

      return response;
    },
    setInitialAccessToken({ commit, getters }) {
      commit('setAccessToken', getters['accessToken']);
    },
    logout({ commit }) {
      commit('setMe', null);
      commit('removeAccessToken');
    }
  },
  modules: {}
});
