import { GenericArrayOfObjects, IApiService, INormalizedResponse } from '@/interfaces/api';
import WSClient from '@/clients/appointment_plus/WSClient';
import {
  mapDock,
  mapLoadType,
  mapWarehouseSchedule,
  mapWarehouse,
  mapDockSchedule,
  mapAppointment,
  mapMaptsDockSchedule,
  getNovaCustomFieldType
} from '@/services/appointment_plus/APNovaMap';
import {
  IAPAppointment,
  IAPDock,
  IAPLoadType,
  IAPMaptsScheduleItem,
  IAPSaptsSchedule,
  IAPWarehouse,
  IGetAppointmentsOptions
} from '@/interfaces/ap';
import { DateTime } from 'luxon';
import { INovaWarehouse, Interval } from '@/interfaces/nova';
import { DockTypes } from '@/enums/appointmentplus/appointmentplusEnums';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as _ from 'lodash';
import { objHasProperty } from '@/services/util';
import { CustomFieldType } from '@/enums/nova/novaEnums';
enum nonExportableAppointmentStatuses {
  cancelled = 'cancelled'
}

export type ConstructorParams = {
  userName: string;
  password: string;
  url: string;
};

const DROPDOWN_FIELD_CHARACTER_LIMIT = 1000;
const LEGACY_UNIQUE_ID_FIELD_NAME = 'appt_id_unique';
const LEGACY_FIELD_NAME = 'strLegacyConfirmationNumber';

export class AppointmentPlusApiService implements IApiService {
  readonly client;
  constructor({ userName, password, url }: ConstructorParams) {
    this.client = new WSClient(userName, password, url);
  }

  async getWarehouses(params = {}, joinData = true): Promise<INormalizedResponse> {
    const response = await this.client.post('Locations/GetLocations', params);
    if (joinData) {
      await this.joinWarehouseData(response);
    }
    response.data = response.data.map(warehouse => {
      return mapWarehouse(warehouse as IAPWarehouse);
    });
    return response;
  }

  async getWarehouseSchedule(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.post('Locations/GetSchedules', params);
    return response;
  }

  async getDaysOff(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.post('Locations/GetDaysOff', params);
    return response;
  }

  async getDockSchedule(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.post('Staff/GetSchedules', params);
    return response;
  }

  async joinWarehouseData(response: INormalizedResponse): Promise<INormalizedResponse> {
    const timezoneRes = await this.client.post('General/GetTimezones');
    const countriesRes = await this.client.post('General/GetCountries');
    const countriesKeyedByIso: { [key: string]: any } = {};
    Object.entries(ISO3166Countries).map(([key, val]) => {
      countriesKeyedByIso[val.iso2.toLowerCase()] = key;
    });

    response.data = await Promise.all(
      response.data.map(async warehouse => {
        warehouse = this.setTimezoneOnWarehouse(warehouse as IAPWarehouse, timezoneRes.data);
        warehouse = this.setCountryOnWarehouse(
          warehouse as IAPWarehouse,
          countriesRes.data,
          countriesKeyedByIso
        );
        const scheduleRes = await this.getWarehouseSchedule({ c_id: warehouse.location_id });
        const warehouseSchedule: IAPSaptsSchedule = scheduleRes.data[0] as IAPSaptsSchedule;
        const daysOffRes = await this.getDaysOff({
          c_id: warehouse.location_id,
          start_date: DateTime.now().toFormat('yyyyMMdd'),
          end_date: DateTime.now().plus({ years: 1 }).toFormat('yyyyMMdd')
        });
        const daysOff: GenericArrayOfObjects = daysOffRes.data;
        this.setScheduleOnWarehouse(warehouse as IAPWarehouse, warehouseSchedule);
        if (daysOff.length > 0) {
          this.setDaysOffOnWarehouse(warehouse as IAPWarehouse, daysOff);
        }
        const warehouseFields = await this.getFields({
          c_id: warehouse.location_id,
          entity_type: 'A'
        });
        const fields: GenericArrayOfObjects = [];
        if (warehouseFields?.length > 0) {
          warehouseFields.forEach(field => {
            const displayName = `${field.display_name}`;
            const fieldViewsToIgnore = ['H'];
            const excludeFields = ['po_number', LEGACY_UNIQUE_ID_FIELD_NAME];
            const skipField =
              (fieldViewsToIgnore.includes(field.required_appointment) &&
                fieldViewsToIgnore.includes(field.required_registration)) ||
              excludeFields.includes(field.field_name);

            let fieldType = getNovaCustomFieldType(field.field_type);
            let fieldDropDownVals =
              fieldType === CustomFieldType.DropDown ||
              fieldType === CustomFieldType.DropDownMultiSelect
                ? field?.options.map((option: { [key: string]: any }) => option.description) ?? []
                : [];

            // To prevent hitting excel limit for a cell, we limit custom drop down vals to 1000 characters otherwise we don't export them
            if (JSON.stringify(fieldDropDownVals).length > DROPDOWN_FIELD_CHARACTER_LIMIT) {
              fieldType = CustomFieldType.String;
              fieldDropDownVals = [];
            }

            // Always include this field regardless of display settings
            if (field.field_name === LEGACY_UNIQUE_ID_FIELD_NAME) {
              this.setLegacyIdField(fields);
            }

            if (!skipField) {
              fields.push({
                name: `${fieldType}${displayName}`,
                type: fieldType,
                label: displayName,
                dropDownValues: fieldDropDownVals,
                hiddenFromCarrier: field.required_registration === 'H',
                requiredForCarrier: field.required_registration === 'R',
                requiredForWarehouse: field.required_appointment === 'R',
                skipField,
                externalFieldName: field.field_name,
                auxId: field.aux_id
              });
            }
          });
        } else {
          // Always include this field regardless of display settings
          this.setLegacyIdField(fields);
        }
        warehouse.custom_fields = fields;
        return warehouse;
      })
    );
    return response;
  }

  setLegacyIdField(fields: GenericArrayOfObjects) {
    fields.push({
      name: LEGACY_FIELD_NAME,
      type: 'str',
      label: 'Legacy Confirmation Number',
      dropDownValues: [],
      hiddenFromCarrier: false,
      requiredForCarrier: false,
      requiredForWarehouse: false,
      externalFieldName: LEGACY_UNIQUE_ID_FIELD_NAME,
      skipField: false,
      auxId: 0
    });
  }

  setTimezoneOnWarehouse(warehouse: IAPWarehouse, timezones: GenericArrayOfObjects): IAPWarehouse {
    const timezone = timezones.find(timezone => {
      return timezone.timezone_id === warehouse.timezone_id;
    });
    warehouse.timezone = timezone?.timezone_name;
    return warehouse;
  }

  setCountryOnWarehouse(
    warehouse: IAPWarehouse,
    countries: GenericArrayOfObjects,
    countriesKeyedByIso: { [key: string]: any }
  ): IAPWarehouse {
    const country = countries.find(country => {
      return country.country_id === warehouse.country_id;
    });
    warehouse.country = countriesKeyedByIso[country?.short_name];
    return warehouse;
  }

  setDaysOffOnWarehouse(warehouse: IAPWarehouse, daysOff: GenericArrayOfObjects) {
    const closedIntervals: Interval[] = [];
    const dateTime = DateTime.fromObject(
      {},
      {
        zone: warehouse.timezone
      }
    );
    daysOff.map(item => {
      closedIntervals.push({
        start: dateTime
          .set({
            year: item.date.substring(0, 4),
            month: item.date.substring(4, 6),
            day: item.date.substring(6, 8)
          })
          .startOf('day')
          .setZone(warehouse.timezone)
          .toUTC()
          .toISO(),
        end: dateTime
          .set({
            year: item.date.substring(0, 4),
            month: item.date.substring(4, 6),
            day: item.date.substring(6, 8)
          })
          .endOf('day')
          .setZone(warehouse.timezone)
          .toUTC()
          .toISO()
      });
    });

    if (closedIntervals.length) {
      warehouse.schedule.closedIntervals = closedIntervals;
    }

    return warehouse;
  }

  setScheduleOnWarehouse(warehouse: IAPWarehouse, schedule: IAPSaptsSchedule) {
    warehouse.schedule = mapWarehouseSchedule(schedule);
    return warehouse;
  }

  // LOAD TYPES
  async getLoadTypes(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.post('Services/GetServices', params);
    response.data = response.data.map(loadType => {
      return mapLoadType(loadType as IAPLoadType);
    });
    return response;
  }

  // DOCKS
  async getDocks(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.post('Staff/GetStaff', params);
    const docks = await this.joinDockData(response);
    return docks;
  }

  async joinDockData(response: INormalizedResponse): Promise<INormalizedResponse> {
    response.data = await Promise.all(
      response.data.map(async dock => {
        const dockLoadTypeRes = await this.getDockLoadTypes({ employee_id: dock.employee_id });
        const dockLoadTypes: GenericArrayOfObjects = dockLoadTypeRes.data;
        const scheduleRes = await this.getDockSchedule({
          c_id: dock.c_id,
          employee_id: dock.employee_id
        });
        this.setLoadTypesOnDock(dock as IAPDock, dockLoadTypes);
        const isMapts = Boolean(scheduleRes?.data?.[0]?.num_appts_per_slot);
        dock.isMapts = isMapts;
        this.setScheduleOnDock(
          dock as IAPDock,
          scheduleRes.data,
          isMapts ? DockTypes.MAPTS : DockTypes.SAPTS
        );
        return mapDock(dock as IAPDock);
      })
    );
    return response;
  }

  async getDockLoadTypes(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.post('Staff/GetServicesOffered', params);
    return response;
  }

  setScheduleOnDock(
    dock: IAPDock,
    schedule?: { [key: string]: any }[],
    type = DockTypes.SAPTS
  ): IAPDock {
    if (type === DockTypes.SAPTS) {
      dock.schedule = mapDockSchedule(dock, schedule?.[0] ?? ({} as IAPSaptsSchedule));
    } else {
      dock.schedule = mapMaptsDockSchedule(dock, schedule as IAPMaptsScheduleItem[]);
    }
    return dock;
  }

  setLoadTypesOnDock(dock: IAPDock, dockLoadTypes: GenericArrayOfObjects): IAPDock {
    let loadTypes: string[] = [];
    dockLoadTypes.forEach(loadType => {
      loadTypes.push(loadType.service_id);
    });
    loadTypes = Array.from(new Set(loadTypes));
    dock.load_type_ids = loadTypes;
    return dock;
  }

  // APPOINTMENTS
  async getAppointments(
    params = {},
    appointmentWarehouses: INovaWarehouse[],
    options?: IGetAppointmentsOptions
  ): Promise<INormalizedResponse> {
    const response = await this.client.post('Appointments/GetAppointments', params);
    const appointments = await this.joinAppointmentData(response, appointmentWarehouses, options);
    return appointments;
  }

  async joinAppointmentData(
    response: INormalizedResponse,
    appointmentWarehouses: INovaWarehouse[],
    options?: IGetAppointmentsOptions
  ) {
    response.data = response.data.filter(appointment => {
      let returnAppt = true;
      Object.keys(nonExportableAppointmentStatuses).forEach(status => {
        if (
          !appointment.appt_status_description ||
          appointment.appt_status_description.toLowerCase().includes(status)
        ) {
          returnAppt = false;
        }
      });
      return returnAppt;
    });
    if (response.data.length < 1) {
      return response;
    }

    const fieldDetailsKeyedByWarehouseId: { [key: string]: any } = {};
    appointmentWarehouses.forEach(warehouse => {
      if (!objHasProperty(fieldDetailsKeyedByWarehouseId, warehouse.externalId)) {
        fieldDetailsKeyedByWarehouseId[warehouse.externalId] = {
          appointmentFields: [],
          customFields: {}
        };
      }

      const template = JSON.parse(warehouse.customApptFieldsTemplate as string) ?? [];
      template.forEach((field: { [key: string]: any }) => {
        if (field.auxId > 0) {
          fieldDetailsKeyedByWarehouseId[warehouse.externalId]['customFields'][field.auxId] = field;
        } else {
          fieldDetailsKeyedByWarehouseId[warehouse.externalId]['appointmentFields'].push(field);
        }
      });
    });

    response.data.map(appointment => {
      const customFields: GenericArrayOfObjects = [];

      fieldDetailsKeyedByWarehouseId[appointment.c_id]['appointmentFields'].forEach(
        (field: { [key: string]: any }) => {
          let value = appointment[field.externalFieldName] ?? '';
          if (field.name === LEGACY_FIELD_NAME) {
            value = appointment.appt_id;
          }
          if (field.type === CustomFieldType.DropDownMultiSelect) {
            value = Array.isArray(value) ? value : value ? [value] : null;
          }

          if (!field.skipField) {
            customFields.push({
              label: field.label,
              type: 'str',
              name: field.name,
              value,
              hiddenFromCarrier: field.hiddenFromCarrier,
              requiredForCarrier: field.requiredForCarrier,
              requiredForWarehouse: field.requiredForWarehouse
            });
          }
        }
      );
      Object.keys(fieldDetailsKeyedByWarehouseId[appointment.c_id]['customFields']).forEach(
        (key: string) => {
          const field = fieldDetailsKeyedByWarehouseId[appointment.c_id]['customFields'][key];
          const customFieldMeta = _.find(appointment.custom_fields, { aux_id: field.auxId });
          field.value = customFieldMeta?.value ?? '';
          if (field.type === CustomFieldType.DropDownMultiSelect) {
            field.value = Array.isArray(field.value)
              ? field.value
              : field.value
              ? [field.value]
              : null;
          }

          if (!field.skipField) {
            customFields.push({
              label: field.label,
              type: field.type,
              name: field.name,
              description: field.description,
              dropDownValues: field.dropDownValues,
              value: field.value,
              hiddenFromCarrier: field.hiddenFromCarrier,
              requiredForCarrier: field.requiredForCarrier,
              requiredForWarehouse: field.requiredForWarehouse
            });
          }
        }
      );

      appointment.customFields = customFields;
      return appointment;
    });

    const customersResponse = await this.getCustomers({
      customer_id: Array.from(new Set(response.data.map(appt => appt.customer_id))).join(',') // Appt customer ids
    });

    response.data = response.data.map(appointment => {
      const customerArr: GenericArrayOfObjects = customersResponse.data;
      if (customerArr.length > 0) {
        this.setCustomerOnAppointment(appointment as IAPAppointment, customerArr);
      }
      return mapAppointment(appointment as IAPAppointment, options);
    });

    return response;
  }

  async getFields(params = {}) {
    const response = await this.client.post('Fields/GetFields', params);
    if (response?.data) {
      response.data = await Promise.all(
        response.data.map(async field => {
          const fieldType = getNovaCustomFieldType(field.field_type);
          if (
            fieldType === CustomFieldType.DropDown ||
            fieldType === CustomFieldType.DropDownMultiSelect
          ) {
            const fieldDetails = await this.getCustomFieldDetails({
              aux_id: field.aux_id
            });
            field.options = fieldDetails.data;
          }

          return field;
        })
      );
    }
    return response?.data;
  }

  setCustomerOnAppointment(
    appointment: IAPAppointment,
    customers: GenericArrayOfObjects
  ): IAPAppointment {
    const customer = customers.find(customer => {
      return customer.customer_id === appointment.customer_id;
    });
    appointment.company_name = customer?.employer;
    appointment.phone = customer?.day_phone || customer?.night_phone || customer?.cell_phone;
    return appointment;
  }

  async getCustomers(params = {}): Promise<INormalizedResponse> {
    return await this.client.post('Customers/GetCustomers', params);
  }

  async getCustomFieldDetails(params = {}): Promise<INormalizedResponse> {
    return await this.client.post('CustomFields/GetCustomFieldsDetails', params);
  }
}

// TODO: Not sure where to put this...so placing it here for now.
export const ISO3166Countries = {
  AFG: { name: 'Afghanistan', iso2: 'AF' },
  ALA: { name: 'Åland Islands', iso2: 'AX' },
  ALB: { name: 'Albania', iso2: 'AL' },
  DZA: { name: 'Algeria', iso2: 'DZ' },
  ASM: { name: 'American Samoa', iso2: 'AS' },
  AND: { name: 'Andorra', iso2: 'AD' },
  AGO: { name: 'Angola', iso2: 'AO' },
  AIA: { name: 'Anguilla', iso2: 'AI' },
  ATA: { name: 'Antarctica', iso2: 'AQ' },
  ATG: { name: 'Antigua and Barbuda', iso2: 'AG' },
  ARG: { name: 'Argentina', iso2: 'AR' },
  ARM: { name: 'Armenia', iso2: 'AM' },
  ABW: { name: 'Aruba', iso2: 'AW' },
  AUS: { name: 'Australia', iso2: 'AU' },
  AUT: { name: 'Austria', iso2: 'AT' },
  AZE: { name: 'Azerbaijan', iso2: 'AZ' },
  BHS: { name: 'Bahamas', iso2: 'BS' },
  BHR: { name: 'Bahrain', iso2: 'BH' },
  BGD: { name: 'Bangladesh', iso2: 'BD' },
  BRB: { name: 'Barbados', iso2: 'BB' },
  BLR: { name: 'Belarus', iso2: 'BY' },
  BEL: { name: 'Belgium', iso2: 'BE' },
  BLZ: { name: 'Belize', iso2: 'BZ' },
  BEN: { name: 'Benin', iso2: 'BJ' },
  BMU: { name: 'Bermuda', iso2: 'BM' },
  BTN: { name: 'Bhutan', iso2: 'BT' },
  BOL: { name: 'Bolivia', iso2: 'BO' },
  BES: { name: 'Bonaire, Sint Eustatius and Saba', iso2: 'BQ' },
  BIH: { name: 'Bosnia and Herzegovina', iso2: 'BA' },
  BWA: { name: 'Botswana', iso2: 'BW' },
  BVT: { name: 'Bouvet Island', iso2: 'BV' },
  BRA: { name: 'Brazil', iso2: 'BR' },
  IOT: { name: 'British Indian Ocean Territory', iso2: 'IO' },
  BRN: { name: 'Brunei Darussalam', iso2: 'BN' },
  BGR: { name: 'Bulgaria', iso2: 'BG' },
  BFA: { name: 'Burkina Faso', iso2: 'BF' },
  BDI: { name: 'Burundi', iso2: 'BI' },
  CPV: { name: 'Cabo Verde', iso2: 'CV' },
  KHM: { name: 'Cambodia', iso2: 'KH' },
  CMR: { name: 'Cameroon', iso2: 'CM' },
  CAN: { name: 'Canada', iso2: 'CA' },
  CYM: { name: 'Cayman Islands', iso2: 'KY' },
  CAF: { name: 'Central African Republic', iso2: 'CF' },
  TCD: { name: 'Chad', iso2: 'TD' },
  CHL: { name: 'Chile', iso2: 'CL' },
  CHN: { name: 'China', iso2: 'CN' },
  CXR: { name: 'Christmas Island', iso2: 'CX' },
  CCK: { name: 'Cocos (Keeling) Islands', iso2: 'CC' },
  COL: { name: 'Colombia', iso2: 'CO' },
  COM: { name: 'Comoros', iso2: 'KM' },
  COG: { name: 'Congo', iso2: 'CG' },
  COD: { name: 'Congo, Democratic Republic of the', iso2: 'CD' },
  COK: { name: 'Cook Islands', iso2: 'CK' },
  CRI: { name: 'Costa Rica', iso2: 'CR' },
  CIV: { name: 'Côte d"Ivoire', iso2: 'CI' },
  HRV: { name: 'Croatia', iso2: 'HR' },
  CUB: { name: 'Cuba', iso2: 'CU' },
  CUW: { name: 'Curaçao', iso2: 'CW' },
  CYP: { name: 'Cyprus', iso2: 'CY' },
  CZE: { name: 'Czechia', iso2: 'CZ' },
  DNK: { name: 'Denmark', iso2: 'DK' },
  DJI: { name: 'Djibouti', iso2: 'DJ' },
  DMA: { name: 'Dominica', iso2: 'DM' },
  DOM: { name: 'Dominican Republic', iso2: 'DO' },
  ECU: { name: 'Ecuador', iso2: 'EC' },
  EGY: { name: 'Egypt', iso2: 'EG' },
  SLV: { name: 'El Salvador', iso2: 'SV' },
  GNQ: { name: 'Equatorial Guinea', iso2: 'GQ' },
  ERI: { name: 'Eritrea', iso2: 'ER' },
  EST: { name: 'Estonia', iso2: 'EE' },
  SWZ: { name: 'Eswatini', iso2: 'SZ' },
  ETH: { name: 'Ethiopia', iso2: 'ET' },
  FLK: { name: 'Falkland Islands (Malvinas)', iso2: 'FK' },
  FRO: { name: 'Faroe Islands', iso2: 'FO' },
  FJI: { name: 'Fiji', iso2: 'FJ' },
  FIN: { name: 'Finland', iso2: 'FI' },
  FRA: { name: 'France', iso2: 'FR' },
  GUF: { name: 'French Guiana', iso2: 'GF' },
  PYF: { name: 'French Polynesia', iso2: 'PF' },
  ATF: { name: 'French Southern Territories', iso2: 'TF' },
  GAB: { name: 'Gabon', iso2: 'GA' },
  GMB: { name: 'Gambia', iso2: 'GM' },
  GEO: { name: 'Georgia', iso2: 'GE' },
  DEU: { name: 'Germany', iso2: 'DE' },
  GHA: { name: 'Ghana', iso2: 'GH' },
  GIB: { name: 'Gibraltar', iso2: 'GI' },
  GRC: { name: 'Greece', iso2: 'GR' },
  GRL: { name: 'Greenland', iso2: 'GL' },
  GRD: { name: 'Grenada', iso2: 'GD' },
  GLP: { name: 'Guadeloupe', iso2: 'GP' },
  GUM: { name: 'Guam', iso2: 'GU' },
  GTM: { name: 'Guatemala', iso2: 'GT' },
  GGY: { name: 'Guernsey', iso2: 'GG' },
  GIN: { name: 'Guinea', iso2: 'GN' },
  GNB: { name: 'Guinea-Bissau', iso2: 'GW' },
  GUY: { name: 'Guyana', iso2: 'GY' },
  HTI: { name: 'Haiti', iso2: 'HT' },
  HMD: { name: 'Heard Island and McDonald Islands', iso2: 'HM' },
  VAT: { name: 'Holy See', iso2: 'VA' },
  HND: { name: 'Honduras', iso2: 'HN' },
  HKG: { name: 'Hong Kong', iso2: 'HK' },
  HUN: { name: 'Hungary', iso2: 'HU' },
  ISL: { name: 'Iceland', iso2: 'IS' },
  IND: { name: 'India', iso2: 'IN' },
  IDN: { name: 'Indonesia', iso2: 'ID' },
  IRN: { name: 'Iran', iso2: 'IR' },
  IRQ: { name: 'Iraq', iso2: 'IQ' },
  IRL: { name: 'Ireland', iso2: 'IE' },
  IMN: { name: 'Isle of Man', iso2: 'IM' },
  ISR: { name: 'Israel', iso2: 'IL' },
  ITA: { name: 'Italy', iso2: 'IT' },
  JAM: { name: 'Jamaica', iso2: 'JM' },
  JPN: { name: 'Japan', iso2: 'JP' },
  JEY: { name: 'Jersey', iso2: 'JE' },
  JOR: { name: 'Jordan', iso2: 'JO' },
  KAZ: { name: 'Kazakhstan', iso2: 'KZ' },
  KEN: { name: 'Kenya', iso2: 'KE' },
  KIR: { name: 'Kiribati', iso2: 'KI' },
  PRK: { name: 'North Korea', iso2: 'KP' },
  KOR: { name: 'South Korea', iso2: 'KR' },
  KWT: { name: 'Kuwait', iso2: 'KW' },
  KGZ: { name: 'Kyrgyzstan', iso2: 'KG' },
  LAO: { name: 'Laos', iso2: 'LA' },
  LVA: { name: 'Latvia', iso2: 'LV' },
  LBN: { name: 'Lebanon', iso2: 'LB' },
  LSO: { name: 'Lesotho', iso2: 'LS' },
  LBR: { name: 'Liberia', iso2: 'LR' },
  LBY: { name: 'Libya', iso2: 'LY' },
  LIE: { name: 'Liechtenstein', iso2: 'LI' },
  LTU: { name: 'Lithuania', iso2: 'LT' },
  LUX: { name: 'Luxembourg', iso2: 'LU' },
  MAC: { name: 'Macao', iso2: 'MO' },
  MDG: { name: 'Madagascar', iso2: 'MG' },
  MWI: { name: 'Malawi', iso2: 'MW' },
  MYS: { name: 'Malaysia', iso2: 'MY' },
  MDV: { name: 'Maldives', iso2: 'MV' },
  MLI: { name: 'Mali', iso2: 'ML' },
  MLT: { name: 'Malta', iso2: 'MT' },
  MHL: { name: 'Marshall Islands', iso2: 'MH' },
  MTQ: { name: 'Martinique', iso2: 'MQ' },
  MRT: { name: 'Mauritania', iso2: 'MR' },
  MUS: { name: 'Mauritius', iso2: 'MU' },
  MYT: { name: 'Mayotte', iso2: 'YT' },
  MEX: { name: 'Mexico', iso2: 'MX' },
  FSM: { name: 'Micronesia', iso2: 'FM' },
  MDA: { name: 'Moldova', iso2: 'MD' },
  MCO: { name: 'Monaco', iso2: 'MC' },
  MNG: { name: 'Mongolia', iso2: 'MN' },
  MNE: { name: 'Montenegro', iso2: 'ME' },
  MSR: { name: 'Montserrat', iso2: 'MS' },
  MAR: { name: 'Morocco', iso2: 'MA' },
  MOZ: { name: 'Mozambique', iso2: 'MZ' },
  MMR: { name: 'Myanmar', iso2: 'MM' },
  NAM: { name: 'Namibia', iso2: 'NA' },
  NRU: { name: 'Nauru', iso2: 'NR' },
  NPL: { name: 'Nepal', iso2: 'NP' },
  NLD: { name: 'Netherlands', iso2: 'NL' },
  NCL: { name: 'New Caledonia', iso2: 'NC' },
  NZL: { name: 'New Zealand', iso2: 'NZ' },
  NIC: { name: 'Nicaragua', iso2: 'NI' },
  NER: { name: 'Niger', iso2: 'NE' },
  NGA: { name: 'Nigeria', iso2: 'NG' },
  NIU: { name: 'Niue', iso2: 'NU' },
  NFK: { name: 'Norfolk Island', iso2: 'NF' },
  MKD: { name: 'North Macedonia', iso2: 'MK' },
  MNP: { name: 'Northern Mariana Islands', iso2: 'MP' },
  NOR: { name: 'Norway', iso2: 'NO' },
  OMN: { name: 'Oman', iso2: 'OM' },
  PAK: { name: 'Pakistan', iso2: 'PK' },
  PLW: { name: 'Palau', iso2: 'PW' },
  PSE: { name: 'Palestine', iso2: 'PS' },
  PAN: { name: 'Panama', iso2: 'PA' },
  PNG: { name: 'Papua New Guinea', iso2: 'PG' },
  PRY: { name: 'Paraguay', iso2: 'PY' },
  PER: { name: 'Peru', iso2: 'PE' },
  PHL: { name: 'Philippines', iso2: 'PH' },
  PCN: { name: 'Pitcairn', iso2: 'PN' },
  POL: { name: 'Poland', iso2: 'PL' },
  PRT: { name: 'Portugal', iso2: 'PT' },
  PRI: { name: 'Puerto Rico', iso2: 'PR' },
  QAT: { name: 'Qatar', iso2: 'QA' },
  REU: { name: 'Réunion', iso2: 'RE' },
  ROU: { name: 'Romania', iso2: 'RO' },
  RUS: { name: 'Russia', iso2: 'RU' },
  RWA: { name: 'Rwanda', iso2: 'RW' },
  BLM: { name: 'Saint Barthélemy', iso2: 'BL' },
  SHN: { name: 'Saint Helena, Ascension and Tristan da Cunha', iso2: 'SH' },
  KNA: { name: 'Saint Kitts and Nevis', iso2: 'KN' },
  LCA: { name: 'Saint Lucia', iso2: 'LC' },
  MAF: { name: 'Saint Martin (French part)', iso2: 'MF' },
  SPM: { name: 'Saint Pierre and Miquelon', iso2: 'PM' },
  VCT: { name: 'Saint Vincent and the Grenadines', iso2: 'VC' },
  WSM: { name: 'Samoa', iso2: 'WS' },
  SMR: { name: 'San Marino', iso2: 'SM' },
  STP: { name: 'Sao Tome and Principe', iso2: 'ST' },
  SAU: { name: 'Saudi Arabia', iso2: 'SA' },
  SEN: { name: 'Senegal', iso2: 'SN' },
  SRB: { name: 'Serbia', iso2: 'RS' },
  SYC: { name: 'Seychelles', iso2: 'SC' },
  SLE: { name: 'Sierra Leone', iso2: 'SL' },
  SGP: { name: 'Singapore', iso2: 'SG' },
  SXM: { name: 'Sint Maarten (Dutch part)', iso2: 'SX' },
  SVK: { name: 'Slovakia', iso2: 'SK' },
  SVN: { name: 'Slovenia', iso2: 'SI' },
  SLB: { name: 'Solomon Islands', iso2: 'SB' },
  SOM: { name: 'Somalia', iso2: 'SO' },
  ZAF: { name: 'South Africa', iso2: 'ZA' },
  SGS: { name: 'South Georgia and the South Sandwich Islands', iso2: 'GS' },
  SSD: { name: 'South Sudan', iso2: 'SS' },
  ESP: { name: 'Spain', iso2: 'ES' },
  LKA: { name: 'Sri Lanka', iso2: 'LK' },
  SDN: { name: 'Sudan', iso2: 'SD' },
  SUR: { name: 'Suriname', iso2: 'SR' },
  SJM: { name: 'Svalbard and Jan Mayen', iso2: 'SJ' },
  SWE: { name: 'Sweden', iso2: 'SE' },
  CHE: { name: 'Switzerland', iso2: 'CH' },
  SYR: { name: 'Syria', iso2: 'SY' },
  TWN: { name: 'Taiwan', iso2: 'TW' },
  TJK: { name: 'Tajikistan', iso2: 'TJ' },
  TZA: { name: 'Tanzania', iso2: 'TZ' },
  THA: { name: 'Thailand', iso2: 'TH' },
  TLS: { name: 'Timor-Leste', iso2: 'TL' },
  TGO: { name: 'Togo', iso2: 'TG' },
  TKL: { name: 'Tokelau', iso2: 'TK' },
  TON: { name: 'Tonga', iso2: 'TO' },
  TTO: { name: 'Trinidad and Tobago', iso2: 'TT' },
  TUN: { name: 'Tunisia', iso2: 'TN' },
  TUR: { name: 'Turkey', iso2: 'TR' },
  TKM: { name: 'Turkmenistan', iso2: 'TM' },
  TCA: { name: 'Turks and Caicos Islands', iso2: 'TC' },
  TUV: { name: 'Tuvalu', iso2: 'TV' },
  UGA: { name: 'Uganda', iso2: 'UG' },
  UKR: { name: 'Ukraine', iso2: 'UA' },
  ARE: { name: 'United Arab Emirates', iso2: 'AE' },
  GBR: { name: 'United Kingdom', iso2: 'GB' },
  UMI: { name: 'United States Minor Outlying Islands', iso2: 'UM' },
  USA: { name: 'United States of America', iso2: 'US' },
  URY: { name: 'Uruguay', iso2: 'UY' },
  UZB: { name: 'Uzbekistan', iso2: 'UZ' },
  VUT: { name: 'Vanuatu', iso2: 'VU' },
  VEN: { name: 'Venezuela', iso2: 'VE' },
  VNM: { name: 'Vietnam', iso2: 'VN' },
  VGB: { name: 'Virgin Islands (British)', iso2: 'VG' },
  VIR: { name: 'Virgin Islands (U.S.)', iso2: 'VI' },
  WLF: { name: 'Wallis and Futuna', iso2: 'WF' },
  ESH: { name: 'Western Sahara', iso2: 'EH' },
  YEM: { name: 'Yemen', iso2: 'YE' },
  ZMB: { name: 'Zambia', iso2: 'ZM' },
  ZWE: { name: 'Zimbabwe', iso2: 'ZW' }
};
