import { INormalizedResponse } from '@/interfaces/api';
import NovaClient from '@/clients/nova/NovaClient';
import { INovaDock, INovaLoadType, INovaWarehouse } from '@/interfaces/nova';
import { mapAppointment, mapDock, mapLoadType, mapWarehouse } from '@/services/nova/NovaMap';

export type ConstructorParams = {
  token: string;
};

export class NovaApiService {
  readonly client;
  constructor({ token }: ConstructorParams) {
    this.client = new NovaClient({ token: token });
  }

  async getOrgs(params = {}): Promise<INormalizedResponse> {
    return await this.client.get('/org', params);
  }

  async getUsers(params = {}): Promise<INormalizedResponse> {
    return await this.client.get('/user', params);
  }

  async getCompanies(params = {}): Promise<INormalizedResponse> {
    return await this.client.get('/company', params);
  }

  async getLoadTypes(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.get('/loadtype', params);
    response.data = response.data.map(loadType => {
      return mapLoadType(loadType);
    });
    return response;
  }

  async getWarehouses(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.get('/warehouse', params);
    response.data = response.data.map(warehouse => {
      return mapWarehouse(warehouse);
    });

    return response;
  }

  async getDocks(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.get('/dock', params);
    response.data = response.data.map(dock => {
      return mapDock(dock);
    });
    return response;
  }

  async getAppointments(params = {}): Promise<INormalizedResponse> {
    const response = await this.client.get('/appointment', params);
    response.data = response.data.map(appointment => {
      return mapAppointment(appointment);
    });
    return response;
  }

  async getLoggedInAsUser(): Promise<INormalizedResponse> {
    return await this.client.get('auth/me');
  }

  async loginAs(userId: string): Promise<INormalizedResponse> {
    return await this.client.post(`wormhole/login-as/${userId}`);
  }

  async createWarehouse(warehouse: INovaWarehouse): Promise<INormalizedResponse> {
    return await this.client.post('/warehouse', warehouse);
  }

  async createLoadType(loadType: INovaLoadType): Promise<INormalizedResponse> {
    return await this.client.post('/loadtype', loadType);
  }

  async createDock(dock: INovaDock): Promise<INormalizedResponse> {
    return this.client.post('/dock', dock);
  }

  async createCompany(companyName: string): Promise<INormalizedResponse> {
    return this.client.post('/company', { name: companyName });
  }

  async getCarriers(params = {}): Promise<INormalizedResponse> {
    return await this.client.get('/carrier', params);
  }

  async createCarrier(carrierData: {
    [key: string]: string | boolean | undefined;
  }): Promise<INormalizedResponse> {
    return this.client.post('/carrier', carrierData);
  }

  async createAppointment(params = {}): Promise<INormalizedResponse> {
    return await this.client.post('/appointment', params);
  }

  async createReserve(params = {}): Promise<INormalizedResponse> {
    return await this.client.post('/appointment/reserve', params);
  }

  async updateOrg(orgId: string, params = {}): Promise<INormalizedResponse> {
    return await this.client.patch(`/org/${orgId}`, params);
  }

  async updateDock(dockId: string, params = {}) {
    return await this.client.patch(`/dock/${dockId}`, params);
  }

  async updateWarehouse(warehouseId: string, params = {}) {
    return await this.client.patch(`/warehouse/${warehouseId}`, params);
  }

  async inviteUser(params = {}) {
    return await this.client.post(`/user/invite`, params);
  }
}
