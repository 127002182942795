
import { defineComponent } from 'vue';
import AuthHero from '@/components/auth/components/AuthHero.vue';
import LoginForm from '@/components/auth/components/LoginForm.vue';

export default defineComponent({
  name: 'LoginPage',
  components: {
    AuthHero,
    LoginForm
  }
});
