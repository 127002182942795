<template>
  <notifications :ignore-duplicates="true">
    <template #body="props">
      <div class="app-notification" :class="props.item.data.appClasses">
        <v-icon class="type-icon">{{ props.item.data.icon }}</v-icon>
        <p class="title" v-if="props.item.title">
          {{ props.item.title }}
        </p>
        <p class="message ma-0 font-size-x-small pr-3" v-html="props.item.text"></p>
        <button class="close" @click="close">
          <v-icon>mdi-close</v-icon>
        </button>
        <a
          class="message ma-0 font-size-x-small font-weight-light"
          v-if="props.item.data.actionFn && props.item.data.actionText"
          @click="props.item.data.actionFn">
          {{ props.item.data.actionText }}
        </a>
      </div>
    </template>
  </notifications>
</template>

<script lang="ts" setup></script>
